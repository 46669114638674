import React, { Component } from 'react';
import '../../assets/css/style.css'

class Loader extends Component{

    constructor(props){
        super(props);

        this.state = {
            active: true
        };
    }

    componentDidMount = () =>{
        const self = this;
        setTimeout(function(){
            self.setState({
                active: false
            });
        }, 100);
    }



    render(){
        
        return (
            <div className={"loader- " + (this.state.active ? 'fadeIn' : 'fadeOut')}>
                {/*<div className="loader-circle"></div>
                <div className="loader-line-mask">
                    <div className="loader-line"></div>
                </div>
        <img src={logo} className="loader-logo" />*/}
            </div>
        );

    }
}

export default Loader;