import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './InfoRimario.css';
import toto from '../../assets/img/figure-Toto.png';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class InfoPoesie extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            link: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }



    componentDidMount = () =>{
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    render(){
      const styleObj = {
    textAlign: "center",
      }
        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={12} xs={12}>
                                <h3 className="title text-center" style={{marginBottom: "10px"}}>Introduzione categoria poesie</h3>

                                La categoria è dedicata ai testi di poesie. Ciascuna poesia viene fornita nelle seguenti modalità:

                                <ul>
                                <li> Testo nella scrittura napoletana</li>
                                <li> Testo nella scrittura napoletana con la scrittura fonetica proposta </li>
                                <li>
                                Testo nella scrittura napoletana con la scrittura fonetica proposta e l’analisi fonologica
                                </li>
                                <li>
                                Lettura in voce del testo napoletano
                                </li>
                                </ul>

                                Forniamo in questa categoria  una ‘Premessa fonologica per la poesia’   e la ‘Trascrizione fonetica adottata’
                                Il numero delle poesie presentate è destinato a crescere.
                                <a href="http://www.napulapp.it/TRASCRIZIONE%20FONETICA%20ADOTTATA.docx" target="_blank"> Scopri di più...</a>


                            </Col>


                        </Row>
                        <Row style={{marginTop : 20}}>
                        Non inclusa in questa o altre sezioni, la piattaforma fornisce una lettura in voce e in video di alcune
                          puntate dedicate alla lettura parziale del testo in dialetto napoletano: Affonzo de Liguori,
                          nòbbele napulitano che pprimma fuie paglietta e ppo’ prèvete santo.<a href="https://www.youtube.com/channel/UChVgR7c7ubMAzNi30LMIg0A"><Button size="sm" style={{marginTop : 20}}> Clicca qui</Button></a>


                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default InfoPoesie;
