import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import Home from './screens/Home/Home.js';
import Categories from './screens/Categories/Categories.js';
import Word from './screens/Word/Word.js';
import Requests from './screens/Requests/Requests.js';
import Contact from './screens/Contact/Contact.js';
import Testo from './screens/Testo/Testo.js';
import Proverbi from './screens/Proverbi/Proverbi.js';
import InfoRimario from './screens/InfoRimario/InfoRimario.js';
import InfoProverbi from './screens/InfoProverbi/InfoProverbi.js';
import InfoPoesie from './screens/InfoPoesie/InfoPoesie.js';
import InfoCanzoni from './screens/InfoCanzoni/InfoCanzoni.js';
import InfoDocumenti from './screens/InfoDocumenti/InfoDocumenti.js';
import InfoParole from './screens/InfoParole/InfoParole.js';

import {HashRouter, Route, Switch} from "react-router-dom";

console.log("%cNapulapp version: 1.94.05", "color: #fefefe; font-size: 1.3rem;");

/**
 * Set Application Routing
 */

const RouteApp = (
    <HashRouter>
        <Switch onUpdate={() => window.scrollTo(0, 0)} >
            <Route exact path="/" name="Home" component={Home} />
            <Route path="/Categorie/:id" name="Categories" component={Categories} />
            <Route path="/Word/:id" name="Parola" component={Word} />
            <Route path="/Requests" name="Richieste" component={Requests} />
            <Route path="/Contact" name="Contatti" component={Contact} />
            <Route path="/Text/:tid" name="Categoria Testi" component={Testo} />
            <Route path="/Proverbi/:tid" name="Categoria Proverbi" component={Proverbi} />
            <Route path="/Info/Rimario" name="InfoRimario" component={InfoRimario}/>
            <Route path ="/Info/Proverbi" name="InfoProverbi" component={InfoProverbi}/>
            <Route path="/Info/Poesie" name="InfoPoesie" component={InfoPoesie}/>
            <Route path="/Info/Canzoni" name="InfoCanzoni" component={InfoCanzoni}/>
            <Route path="/Info/Documenti" name="InfoDocumenti" component={InfoDocumenti}/>
            <Route path="/Info/Parole" name="InfoParole" component={InfoParole}/>
            <Route exact path="/*" name="404" component={Home} />
        </Switch>
    </HashRouter>
);

ReactDOM.render(RouteApp, document.getElementById('root'));
