import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './InfoRimario.css';
import toto from '../../assets/img/figure-Toto.png';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class InfoParole extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            link: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }



    componentDidMount = () =>{
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    render(){
      const styleObj = {
    textAlign: "center",
      }
        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={12} xs={12}>
                                <h3 className="title text-center" style={{marginBottom: "10px"}}>Introduzione sezione parole</h3>
                                <p>
                                NAPULAPP è una iniziativa culturale, che si propone di diffondere e approfondire la conoscenza della lingua napoletana, favorendone, così, una maggiore fruizione da parte dei lettori e degli autori.<br/>
                                L’applicazione consente agli utenti la consultazione di un’ampia gamma di parole e locuzioni napoletane, di cui si offre la scrittura etimologica, la categoria grammaticale, il significato sommario in italiano, la scrittura fonetica, la lettura in voce e le norme di pronuncia che le riguardano. <br/>La raccolta di parole e locuzioni consultabili non intende, né può, avere la funzione di un dizionario.<br/>
                                Il sistema della consultazione delle parole non è perfetto; pecca, in un particolare, per eccesso e non per difetto. Infatti, alcuni sintagmi vengono richiamati sia con la lettera iniziale del primo termine, sia con quella del secondo termine. Per esempio, il sintagma  “ ’o ffraceto” è presente sia nel gruppo delle parole inizianti con la vocale ‘o’, sia in quello delle parole inizianti con la consonante ‘f’.
                                <br/>Arricchiscono questa gratuita e principale funzione dell’applicazione alcune sezioni dedicate a poesie, canzoni, proverbi, documenti e rimario.
                                <br/>Il responsabile dei contenuti dell’applicazione è GIOVANNI VITALE.<br/>
                                </p>
                                Forniamo in questa categoria la Trascrizione fonetica adottata
                                <a href="http://www.napulapp.it/TRASCRIZIONE%20FONETICA%20ADOTTATA2.docx" target="_blank"> Scopri di più...</a>

                            </Col>
                        </Row>

                        <Row style={{marginTop : 20}}>
                          Non inclusa in questa o altre sezioni, la piattaforma fornisce una lettura in voce e in video di alcune
                          puntate dedicate alla lettura parziale del testo in dialetto napoletano: Affonzo de Liguori,
                          nòbbele napulitano che pprimma fuie paglietta e ppo’ prèvete santo.<a href="https://www.youtube.com/channel/UChVgR7c7ubMAzNi30LMIg0A"><Button size="sm" style={{marginTop : 20}}> Clicca qui</Button></a>


                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default InfoParole;
