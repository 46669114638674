import React, { Component } from 'react';
import {Col, Row, Button} from 'react-bootstrap'

import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './Categories.css';
import WordCard from '../../components/Cards/Cards';
import SearchComponent from '../../components/Search/Search';
import CategoryList from '../../components/CategoryList/CategoryList.js';
import CategoryLists from '../../services/Categoria/Categoria.js';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from '../../components/Loader/LoadingFullScreen';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import SearchService from '../../services/Search/Search';



class Home extends Component{

    constructor(props){
        super(props);

        this.state = {
            catActive: 'Parole',
            catLoad: '',
            trends: null,
            hasMoreItems: true,
            pageStart: 0,
            filters__: {"parole" :[]},
            word: '',
            loadItemFilters: false
        };

    }



    componentDidMount = () => {
        document.body.className = 'categories';
        window.scrollTo(0, 0);

        const { id } = this.props.match.params;

        CategoryLists.list(id, [0, 10]).then(response => {
            if(response.text){
                response = JSON.parse(response.text);
                if(response.parole){
                    this.setState({
                        trends: response.parole
                    });
                }else if(response.documenti){
                    this.setState({
                        trends: response.documenti
                    });
                }else if(response.testi){
                    this.setState({
                        trends: response.testi
                    });
                }
            }else{
                console.log(response);
            }
        }).catch(err => {
            console.log(err);
        });



        this.setState({
            slider: document.querySelector(".slider_body")
        });


        if(id !== null){
            if(this.state.catActive !== id){
                  this.setState({
                      catActive: id
                  });


            }
        }
    }


    componentDidUpdate = () => {
        const { id } = this.props.match.params;
        console.log(id);
        if(id !== null){
            if(this.state.catLoad !== id){
                this.setState({
                    trends: null
                });

                CategoryLists.list(id,[0, 10]).then(response => {
                    if(response.text){
                        response = JSON.parse(response.text);
                        if(response.parole){
                            this.setState({
                                trends: response.parole
                            });
                        }else if(response.documenti){
                            this.setState({
                                trends: response.documenti
                            });
                        }else if(response.testi){
                            this.setState({
                                trends: response.testi
                            });
                        }
                    }else{
                        console.log(response);
                    }
                }).catch(err => {
                    console.log(err);
                });


                this.setState({
                    catLoad: id,
                    hasMoreItems: true,
                });

                this.resetScroll();
            }
        }

    }

    loadItems(page) {
        var self = this;
        const { id } = this.props.match.params;
        if(this.state.hasMoreItems){

            CategoryLists.list(id,[(page + "0"), 10]).then(response => {
                if(response.text){ //console.log(response.body.parole);
                    var tracks = self.state.trends;
                    response = response.body;//JSON.parse(response.text);

                    if(response.parole){
                        if(response.parole.length > 0){
                            response.parole.map((track) => {
                                tracks.push(track);
                            });

                            self.setState({
                                trends: tracks
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }
                    }else if(response.documenti){
                        if(response.documenti.length > 0){
                            response.documenti.map((track) => {
                                tracks.push(track);
                            });

                            self.setState({
                                trends: tracks
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }

                    }else if(response.testi){
                        if(response.testi.length > 0){
                            response.testi.map((track) => {
                                tracks.push(track);
                            });

                            self.setState({
                                trends: tracks
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }
                    }else{
                        self.setState({
                            hasMoreItems: false
                        });
                    }

                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }

    loadFilterItems(page) {
        var self = this;
        const { id } = this.props.match.params;
        if(this.state.hasMoreItems && this.state.word != ''){

            SearchService.search(this.state.word,[(page + "0"), 10]).then(response => {
                if(response.text){ //console.log(response.body.parole);
                    var tracks__ = self.state.filters__;
                    response = response.body;//JSON.parse(response.text);
                    if(response.parole){
                        if(response.parole.length > 0){
                            response.parole.map((track) => {
                                tracks__['parole'].push(track);
                            });

                            self.setState({
                                filters__: tracks__
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }
                    }else if(response.documenti){
                        if(response.documenti.length > 0){
                            response.documenti.map((track) => {
                                tracks__.push(track);
                            });

                            self.setState({
                                filters__: tracks__
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }

                    }else if(response.testi){
                        if(response.testi.length > 0){
                            response.testi.map((track) => {
                                tracks__.push(track);
                            });

                            self.setState({
                                filters__: response.testi
                            });
                        }else{
                            self.setState({
                                hasMoreItems: false
                            });
                        }
                    }else{
                        self.setState({
                            hasMoreItems: false
                        });
                    }

                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });
        }

    }

    callbackList = ($id) => {
        this.props.history.push('/Categorie/' + $id);
        this.setState({
            catActive: $id,
            loadItemFilters: false
        });
    }


    truncate = (str) => {

    return str && str.length > 75 ? str.substring(0, 74) + "..." : str;
    }

    truncate2 = (str) => {
    return str && str.length > 25 ? str.substring(0, 24) + "..." : str;
    }

    resetScroll() {
        this.scroll.pageLoaded = 0
    }

    moreItems = (e) => {
        console.log("altro");
        this.setState({
            loadItemFilters: true,
            pageStart: 0
        });

        /**
         * Aggiornamento lista filtrata
         */
        if(this.state.catActive == 'Rimario')
        {
          SearchService.searchRimario(e, [0,10]).then(response => {
              if(response.text){

                  response = JSON.parse(response.text);
                  this.setState({
                      filters__: response,
                      word: e
                  });
              }else{
                  console.log(response);
              }
          }).catch(err => {
              console.log(err);
          });
        }
        else {

          SearchService.search(e, [0,10]).then(response => {
              if(response.text){

                  response = JSON.parse(response.text);
                  console.log(response)
                  this.setState({
                      filters__: response,
                      word: e
                  });
              }else{
                  console.log(response);
              }
          }).catch(err => {
              console.log(err);
          });
        }

    }

    render(){

        const { catActive } = this.state;

        const loader = <Col md={12} className="loader-ctn text-center" ><span className="btn-loader"><span></span><span></span><span></span><span></span></span></Col>;

        var items = [], items_filter = [];
        console.log(this.state)
        if(this.state.trends !== null){

            this.state.trends.map((val, i) => {
                if(val !== undefined){
                    if(catActive === "Documenti"){
                        items.push(
                            <WordCard key={i} _id={val._id} word={val.titolo} text={val.nota} link={'#/document/' + val._id} category={[catActive]} />
                        );
                    }else{
                        items.push(
                            <WordCard key={i} _id={val._id} word={(catActive == "Parole" ? val.parola : val.titolo)} text={(catActive == "Parole" ? val.Proverbi : this.truncate(val.contenuto))} text2={(catActive == "Parole" ? val.funzione_grammaticale : this.truncate2((val.autore ? val.autore +" del "+val.anno : "")))} link={( catActive == "Parole" ? '#/word/' + val._id : catActive == "Proverbi" ? "#/Proverbi/" + val._id : '#/text/' + val._id)} category={[ catActive]} />
                        );
                    }
                }
            });
        }

        if(this.state.loadItemFilters){
            if(this.state.filters__['parole'] !== []){
              // console.log(this.state.filters__[catActive.toLowerCase()]);
              //console.log(this.state.filters__)
              //catActive.toLowerCase()
                this.state.filters__['parole'].map((val, i) => {
                  console.log("puttan",val);
                    if(val !== undefined){
                        items_filter.push(
                            <WordCard key={i} _id={val._id} word={(catActive == "Parole" || catActive == "Rimario" ? val.parola : val.titolo)} text={(catActive == "Parole" || catActive == "Rimario" ? val.Proverbi : val.contenuto)} text2={(catActive == "Parole" || catActive == "Rimario" ? val.funzione_grammaticale : (val.autore ? val.autore +" del "+val.anno : ""))} link={( catActive == "Parole" || catActive == "Rimario"? '#/word/' + val._id : catActive == "Proverbi" ? "#/Proverbi/" + val._id : '#/text/' + val._id)} category={[ catActive]} />
                        );
                    }
                });
            }
        }

        return (
            <div>
                <Loader />
                <Header />

                {/*
                    Section Categories
                */}
                <section id="categories" className="bg-f9fafb ext_page">
                    <div className="slider-card">
                        <div className="slider_menu">
                            <div className="slider_body">
                                <CategoryList active={catActive} callback={this.callbackList.bind(this)} alignCenter={true} />

                            </div>
                        </div>
                    </div>
                </section>

                {/*
                    Section Current Trends
                */}
                <section id="currentTrends" className="sectionpadd lateral-50-padd">
                    <Row>
                        <Col className="text-center">
                            <h3 className="title">Categoria: {catActive == 'Documenti' ? 'Documenti e News' : catActive}

                                   <a href={"#/Info/" + this.state.catActive}><Button variant="info" style={{marginLeft: 10}}> <FontAwesomeIcon icon={faInfo}></FontAwesomeIcon></Button></a>

                             </h3>
                            {
                              (catActive === 'Rimario' ? (
                                  <p style={{fontSize: 20}}> Elenco rime possibili: <a href="http://www.napulapp.it/ELENCO%20RIME.docx" target="_blank"><Button size="sm"> Clicca qui</Button></a></p>
                              ) : "")
                            }

                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-left">
                            <SearchComponent style={catActive} moreItems={this.moreItems} placeholder={catActive === "Rimario" ? "Cerca parole in rima...": ""} />
                        </Col>
                    </Row>

                    {/*
                        (this.state.trends !== null ? this.state.trends.map((val, index) => (
                            <WordCard key={index} word={(catActive == "Parole" ? val.parola : val.titolo)} text={(catActive == "Parole" ? val.Proverbi : val.contenuto)} text2={(catActive == "Parole" ? val.funzione_grammaticale : val.autore+" del "+val.anno)} link={( catActive == "Parole" ? '#/word/' + val._id : '#/text/' + val.id)} />
                        )) : '')
                    */}

                    {
                        this.state.loadItemFilters === false ?
                            <InfiniteScroll
                                pageStart={this.state.pageStart}
                                loadMore={this.loadItems.bind(this)}
                                hasMore={this.state.hasMoreItems}
                                loader={loader}
                                className={"row"}
                                ref={ (scroll) => { this.scroll = scroll; } }
                                >
                                    {items}
                            </InfiniteScroll>
                        :
                            <InfiniteScroll
                                pageStart={this.state.pageStart}
                                loadMore={this.loadFilterItems.bind(this)}
                                hasMore={this.state.hasMoreItems}
                                loader={loader}
                                className={"row"}
                                ref={ (scroll) => { this.scroll = scroll; } }
                                >
                                    {items_filter}
                            </InfiniteScroll>
                    }
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default Home;
