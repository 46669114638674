import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './Requests.css';
import toto from '../../assets/img/figure-Toto.png';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class Requests extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            link: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }

    handleChange = ($event) =>{
        const cnt = $event.target.name;

        if(cnt == "name"){
            this.setState({
                name: $event.target.value
            });
        }else if(cnt == "email"){

            const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            re.test($event.target.value);
            this.setState({
                email: $event.target.value,
                errorEmail: !re.test($event.target.value)
            });
        }else if(cnt == "message"){
            this.setState({
                message: $event.target.value
            });
        }else if(cnt == "link"){
            this.setState({
                link: $event.target.value
            });
        }

        if(this.state.name.length >= 1 && this.state.email.length >= 5){
            this.setState({
                sendEmail: true
            });
        }

        $event.preventDefault();
    }

    handleSendForm = (e) => {
        this.setState({
            sendEmail: false
        });
        const self = this;
        //document.getElementById('sendmess').submit();
        /*console.log(this.state);*/
        this.fileUpload(this.state.file).then((response)=>{
            self.setState({
                sendEmail: true,
                response: response.data
            }); console.log(response.data);

            if(response.data.includes("OK:")){
                self.setState({
                    name: '',
                    email: '',
                    message: '',
                    link: ''
                });
            }
        }).catch((error) => {
            console.log(error);
        });

        e.preventDefault();
    }

    fileUpload(file){
        const url = 'http://paco.wc.lt/zetaware/napulappSiteEmailForm/form.php';
        //const url = 'http://localhost/test/form.php';

        const formData = new FormData();
        formData.append('attachment',file);
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('message', this.state.message);
        formData.append('link', this.state.link);

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return post(url, formData,config);
    }

    upFile = (file) => {
        this.setState({
            file: file.target.files[0]
        });
    }

    componentDidMount = () =>{
        document.body.className = 'requests';
        window.scrollTo(0, 0);
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    render(){

        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={6} xs={12}>
                                <h3 className="title text-center" style={{marginBottom: "10px"}}>Richieste</h3>
                                <p style={{textAlign: 'center', marginBottom: 50}}></p>

                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1_">
                                        <Form.Control type="text" className="inputForm name" value={this.state.name} name="name" onChange={this.handleChange} placeholder="Nome: Marco Rossi" />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput2_">
                                        <Form.Control type="email" className={"inputForm email " + (this.state.errorEmail ? "error" : "")} name="email" value={this.state.email} onChange={this.handleChange} placeholder="Email: name@example.com" />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlTextarea1_">
                                        <Form.Control as="textarea" className="inputForm message" name="message"  value={this.state.message} onChange={this.handleChange} rows="4" placeholder="Scrivi una nota." />
                                    </Form.Group>

                                    <Form.Group controlId="attachment">
                                        <div className="inputForm budget file" >
                                            <Form.Label for="attachment">Allega Documento (Pdf, doc, txt)</Form.Label>
                                            <Form.Control type="file" name="attachment" onChange={this.upFile} accept=".doc, .docx, .txt, .pdf" />
                                        </div>
                                    </Form.Group>
                                    <small>* Ricorda, potrai allegare solo documenti con estensione .Pdf, .docx, .doc, .txt.</small>

                                    <Form.Group controlId="exampleForm.ControlInput4_" style={{marginTop: 25}}>
                                        <Form.Control type="text" className="inputForm budget" onChange={this.handleChange}  value={this.state.link} name="link" placeholder="Allega un link: http://url" />
                                    </Form.Group>

                                    <Button variant="primary" disabled={this.state.sendEmail === false} onClick={this.handleSendForm} className="btn-moby">Invia Richiesta</Button>
                                    <div>
                                        {this.state.response}
                                    </div>
                                </Form>
                            </Col>
                            <Col style={{textAlign: 'right'}} md={6} xs={12} className="noMoby">
                                <img src={toto} alt="image 2" className="toto" />
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default Requests;
