import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './InfoRimario.css';
import toto from '../../assets/img/figure-Toto.png';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class InfoDocumenti extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            link: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }



    componentDidMount = () =>{
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    render(){
      const styleObj = {
    textAlign: "center",
      }
        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={12} xs={12}>
                                <h3 className="title text-center" style={{marginBottom: "10px"}}>Introduzione categoria documenti</h3>
                                Nella categoria Documenti sono forniti materiali non ricadenti nell’ambito delle altre sezioni. Potremo avere, tra l’altro, in questa categoria:
                                <ul>
                                <li> Narrazioni in dialetto napoletano</li>
                                <li> Preghiere in napoletano</li>
                                <li>
                                Preghiere in napoletano e trascrizione fonetica
                                </li>
                                <li>
                                Lettura in voce di preghiere in napoletano
                                </li>
                                <li>Pagine di scrittura napoletana</li>
                                <li>Versioni in napoletano di poesie o prose</li>
                                <li>Riflessioni sulla pronuncia o sulla scrittura del napoletano</li>
                                <li>Documentazioni su errori di scrittura</li>
                                <li>Documenti sul rapporto tra lingua italiana e dialetto napoletano</li>
                                <li>Informazioni relative a nuovi contenuti pubblicati nell’App</li>
                                <li>Informazioni relative ad eventi esterni all’App</li>
                                </ul>
                                Precisiamo che le scelte riguardanti la scrittura dei testi sono conformi alle norme da noi
                                proposte.
                                Inoltre, riferiamo che il testo dei Proverbi è stato elaborato in maniera da migliorarne, a nostro
                                parere, la prosodia e favorirne la memorizzazione.
                                <a href="http://www.napulapp.it/TRASCRIZIONE%20FONETICA%20ADOTTATA.docx" target="_blank"> Scopri di più...</a>

                            </Col>
                        </Row>

                        <Row style={{marginTop : 20}}>
                        Non inclusa in questa o altre sezioni, la piattaforma fornisce una lettura in voce e in video di alcune
                          puntate dedicate alla lettura parziale del testo in dialetto napoletano: Affonzo de Liguori,
                          nòbbele napulitano che pprimma fuie paglietta e ppo’ prèvete santo.<a href="https://www.youtube.com/channel/UChVgR7c7ubMAzNi30LMIg0A"><Button size="sm" style={{marginTop : 20}}> Clicca qui</Button></a>


                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default InfoDocumenti;
