import React, { Component } from 'react';
import {Col,  Card, Tooltip, OverlayTrigger, Image } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Configuration } from '../../constants/Configuration';
import Doc from '../../assets/img/doc.png';


class Word extends Component{

    constructor(props){
        super(props);

        this.state = {
            load: true,
            audio: true,
            catCorr: this.props.category,
            showImg: false
        };

        this.audio = new Audio("")

    }

    componentDidMount = () =>{

        if(this.props.text2 === undefined){
            $('.card-preview').addClass('show');
            $('.document-img').css({display: 'none'});

        }
        setTimeout(function(){
            $('.card-preview').removeClass('show');
            $('.document-img').css({display: 'block'});

        }, 500);

        const self = this;
        this.audio = new Audio(Configuration.MEDIA + this.props._id);
        this.audio.onerror = function(){
            self.setState({
                audio: false
            });
        }

    }

    componentDidUpdate = () => {

    }

    componentWillUnmount = () =>{
        if(this.props.text){
            $('.card-preview').addClass('show');
            $('.document-img').css({display: 'none'});
            if($('.card-preview.show')[0]){
                setTimeout(function(){
                    $('.card-preview').removeClass('show');
                    $('.document-img').css({display: 'block'});
                }, 500);
            }
        }
    }


    render(){

        const { word, text, text2, type, link, category, _id } =  this.props;

        return(
            <Col xl={3} lg={4} sm={6} className="text-center " style={{position: 'relative', zIndex: 1}}>
                <a href={(category[0] === "Documenti" ? Configuration.DOCUMENT + _id : link)} target={category[0] === "Documenti" ? "_blank" :""} className="no-decoration">
                    {/*<OverlayTrigger
                        placement={"bottom"}
                        overlay={
                            <Tooltip id={`tooltip-bottom`}>
                                {word}
                            </Tooltip>
                    }>*/}
                        <Card className="card_f54w">
                            <div className={`${"card-preview show"} `}>
                                <div className="card-title" />
                                <div className="card-t1" />
                                <div className="card-t2" />
                            </div>
                            <Card.Header as="h5">
                                {(null ? (
                                    <div className="label-card btn-primary">{type}</div>
                                ): "")}
                            </Card.Header>
                            <Card.Body style={category[0] === "Documenti" ? {paddingTop: 60, textAlign: 'center'} : {}}>
                                {
                                    category[0] === "Documenti" ? <Image src={Doc} alt="Document" rel="Document" className="document-img" style={{position: 'absolute', marginTop: -130, width: 100, transform: 'translateX(-50%)', left: '50%'}} /> : ""
                                }
                                  <Card.Title><p style={category[0] === "Documenti" ? {width: '100%'} : {}}><span>{word}</span></p> {(this.state.audio ? category[0] === "Documenti" ? "" :(<div className="label-card-play"><FontAwesomeIcon icon={faPlayCircle} /> Riproducibile</div>) : "")}</Card.Title>
                                <Card.Text>{text}</Card.Text>
                                <small><i>{text2}</i></small>
                                <p className="link"><FontAwesomeIcon icon={faChevronRight} /></p>
                            </Card.Body>
                        </Card>
                    {/*</OverlayTrigger>*/}

                </a>
            </Col>
        );
    }

}

export default Word;
