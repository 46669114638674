import { Configuration } from '../../constants/Configuration';
import superagent from 'superagent';


/**
 *
 * @param word
 * @returns { Promise<*> }
 */


function search(word, order){
    return superagent.get(Configuration.ENDPOINT.replace('{id}', '6lj9h6i6ob') + '/cerca/ricercaBase//'+word+'/'+order[0]+'/' + order[1], {})
    .set('accept', 'json')
    .send();
}

function searchRimario(word, order){
    word = encodeURI(word);
    return superagent.get(Configuration.ENDPOINT.replace('{id}', '6lj9h6i6ob') + 'cerca/ricercaRimario/'+word+'/'+order[0]+'/' + order[1], {})
    .set('accept', 'json')
    .send();
}

const SearchService = {
    search,
    searchRimario
}

export default SearchService;
