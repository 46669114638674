import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './InfoRimario.css';
import toto from '../../assets/img/figure-Toto.png';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class InfoRimario extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            link: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }



    componentDidMount = () =>{
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    render(){
      const styleObj = {
    textAlign: "center",
      }
        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={12} xs={12}>
                                <h3 className="title text-center" style={{marginBottom: "10px"}}>Introduzione al Rimario</h3>
                                <ul>
                                <li> Il nostro rimario consente la consultazione di più di 1500  rime.</li>
                                <li> Nel napoletano, come nell’italiano, quasi tutte le vocali toniche finali di parola portano l’accento grammaticale. In alcuni monosillabi forti, però, la vocale non porta l’accento grammaticale. Questi monosillabi, a prescindere dalla presenza o meno dell’accento grammaticale, possono far rima con una parola tronca (e quindi accentata). Poiché, al momento, il nostro sistema non ci consente di richiamare questi monosillabi non accentati, ne riportiamo qui un elenco: me (pron. e non particella pronominale  [mé]), te (pron. e non particella pronominale [té]), ca (cong., ‘poiché’ [ka]), che (agg. esclamativo indefinito: comme a che ‘in maniera eccezionale’ [kómm a kké]), cu (prep., ‘con’ [ku]), fa (nel senso di avverbio: n’anno fa, ‘un anno fa’; nel senso di sostantivo in quanto nota musicale [fa]), ma (cong., ‘ma’ [ma]), mo (avv., ‘ora, adesso’ [mò]), ’nfra (prep., ‘fra’ [nfra]), ’ntra (prep., ‘tra’ [ntra]),  nu  (art. ‘un’ [nu]), re (nota musicale) [rrè]), si (cong.. ‘se’ [si]), tre num. [tré]), tu (pron. ‘tu’ [tu]). Questi monosillabi possono far rima, ad esempio, con ‘parlà’, ‘pecché’,  ‘sentì’, ‘cummò’, ‘raù’.</li>
                                <li>
                               Come si sa, per l’italiano e per il napoletano, la rima non tiene conto della diversa apertura delle vocali toniche, cioè del loro suono aperto o chiuso; di conseguenza nelle due lingue sono utilizzate rime come le seguenti: amóre-cuòre e ammóre-còre. Ciò premesso, noi abbiamo ritenuto opportuno distinguere le rime in base alla diversa apertura della vocale tonica. Pertanto nell’elenco delle nostre rime troveremo, ad esempio, -èllo ed -éllo, -óre ed -òre. In tal modo, chi volesse nelle sue rime tener conto della diversa apertura delle vocale e ed o è servito a puntino. Riferiamo, inoltre, a proposito della rima, che nel napoletano la desinenza, l’ultima vocale atona, ha suono indistinto, buono per tutte le rime. Ad esempio, in un testo di Di Giacomo (’E ttrezze ’e Carulina), la parola ‘petteno’ fa rima con ‘pettena’, poiché la vocale atona finale delle due parole ha suono neutro. Da aggiungere che in alcuni casi la vocale atona finale di parola va pronunciata col suo suono proprio.
                                </li>
                                <li>
                                 Al momento, il sistema di ricerca delle rime è imperfetto. In alcuni casi, infatti,quando la rima ricercata è composta di più di 3 o 4 lettere, il sistema può riportare,impropriamente, come rima, una stringa di lettere che rima non è. Ci auguriamo dipoter superare tale inconveniente.
                                </li>
                                <li>
                                Nel corpus dei lemmi abbiamo fornito la forma dell’infinito presente dei verbi; in maniera non sistematica abbiamo proposto qualche forma dell’indicativo presente, del gerundio o del participio passato. Qui raccogliamo in un prospetto, ad integrazione del numero delle rime, quelle dei diversi tempi dei verbi regolari.
                                </li>
                                </ul>

                                <Row style={{marginTop : 20}}>
                                Precisiamo che le scelte riguardanti la scrittura dei testi sono conformi alle norme da noi
                                proposte.

                                  Non inclusa in questa o altre sezioni, la piattaforma fornisce una lettura in voce e in video di alcune
                                  puntate dedicate alla lettura parziale del testo in dialetto napoletano: Affonzo de Liguori,
                                  nòbbele napulitano che pprimma fuie paglietta e ppo’ prèvete santo.<a href="https://www.youtube.com/channel/UChVgR7c7ubMAzNi30LMIg0A"><Button size="sm" style={{marginTop : 10}}> Clicca qui</Button></a>
                                </Row>

<div style={styleObj}>

1a Am&agrave;
2a Ten&eacute;
3a Sent&igrave;

<p >&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<br /> <b> Presente indicativo </b></p>
<p>1a p. s. &ndash;&agrave;&hellip;o1 1a p. s. &ndash;&hellip;o2 1a p. s. &ndash;&hellip;o</p>

<p><br />1a p. s. &ndash;&agrave;&hellip;o 1a p. s. &ndash;&hellip;o1a p. s. &ndash;&hellip;o <br />2a p. s. &ndash;&agrave;&hellip;e 2a p. s. &ndash;&hellip;e 2a p. s. &ndash;&hellip;e <br />3a p. s. &ndash;&agrave;&hellip;a 3a p. s. &ndash;&hellip;e 3a p. s. &ndash;&hellip;e <br /> 1a p. pl. &ndash;&agrave;mmo 1a p. pl. &ndash;&igrave;mmo 1a p. pl. -&igrave;mmo <br /> 2a p. pl. &ndash;&agrave;te 2a p. pl. &ndash;&igrave;te 2a p. pl. -&igrave;te <br /> 3a p. pl. &ndash;&agrave;&hellip;ano 3a p. pl. &ndash;&hellip;eno 3a p. pl. &ndash;&hellip;eno </p>

<p><br />2a p. s. &ndash;&agrave;&hellip;e 2a p. s. &ndash;&hellip;e 2a p. s. &ndash;&hellip;e<br />3a p. s. &ndash;&agrave;&hellip;a 3a p. s. &ndash;&hellip;e 3a p. s. &ndash;&hellip;e<br />1a p. pl. &ndash;&agrave;mmo 1a p. pl. &ndash;&igrave;mmo 1a p. pl. -&igrave;mmo<br />2a p. pl. &ndash;&agrave;te 2a p. pl. &ndash;&igrave;te 2a p. pl. -&igrave;te<br />3a p. pl. &ndash;&agrave;&hellip;ano 3a p. pl. &ndash;&hellip;eno 3a p. pl. &ndash;&hellip;eno</p>
<p> <b> Imperfetto indicativo </b></p>
<p><br />1a p. s. &ndash;&agrave;vo (-&agrave;va) 1a p. s. &ndash;&eacute;vo (-&eacute;va) 1a p. s. &ndash;&eacute;vo (-&eacute;va)<br />2a p. s. &ndash;&agrave;ve 2a p. s. &ndash;&igrave;ve 2a p. s. &ndash;&igrave;ve<br />3a p. s. &ndash;&agrave;va 3a p. s. &ndash;&eacute;va 3a p. s. &ndash;&eacute;va<br />1a p. pl. &ndash;&agrave;vemo 1a p. pl. &ndash;&eacute;vamo 1a p. pl. -&eacute;vamo<br />2a p. pl. &ndash;&agrave;veve 2a p. pl. &ndash;&igrave;veve 2a p. pl. -&igrave;veve<br />3a p. pl. &ndash;&agrave;vano 3a p. pl. &ndash;&eacute;vano 3a p. pl. &ndash;&eacute;vano</p>
<p> <b> Passato remoto </b></p>
<p>1a p. s. &ndash;&agrave;ie 1a p. s. &ndash;&egrave;tte 1a p. s. &ndash;&egrave;tte<br />2a p. s. &ndash;&agrave;ste 2a p. s. &ndash;&igrave;ste 2a p. s. &ndash;&igrave;ste<br />3a p. s. &ndash;&agrave;ie 3a p. s. &ndash;&egrave;tte 3a p. s. &ndash;&egrave;tte<br />1a p. pl. &ndash;&agrave;iemo 1a p. pl. &ndash;&egrave;ttemo 1a p. pl. -&egrave;ttemo<br />2a p. pl. &ndash;&agrave;steve 2a p. pl. &ndash;&igrave;steve 2a p. pl. -&igrave;steve<br />3a p. pl. &ndash;&agrave;ieno/-&agrave;rono 3a p. pl. &ndash;&egrave;tteno/-&egrave;tteno 3a p. pl. &ndash;&egrave;tteno/-&egrave;tteno</p>
<p> <b> Futuro semplice </b></p>
<p>1a p. s. &ndash;&agrave;ggio 1a p. s. &ndash;&agrave;ggio 1a p. s. &ndash;&agrave;ggio<br />2a p. s. &ndash;&agrave;ie 2a p. s. &ndash;&agrave;ie 2a p. s. &ndash;&agrave;ie<br />3a p. s. &ndash;&agrave; 3a p. s. &ndash;&agrave; 3a p. s. &ndash;&agrave;<br />1a p. pl. &ndash;&agrave;mmo 1a p. pl. &ndash;&agrave;mmo 1a p. pl. -&agrave;mmo<br />2a p. pl. &ndash;&agrave;te 2a p. pl. &ndash;&agrave;te 2a p. pl. -&agrave;te<br />3a p. pl. &ndash;&agrave;nno 3a p. pl. &ndash;&agrave;nno 3a p. pl. &ndash;&agrave;nno</p>
<p> <b> <br />Congiuntivo presente </b> </p>
<p><br />1a p. s. &ndash;&agrave;&hellip;o 1a p. s. &ndash;&hellip;o 1a p. s. &ndash;&hellip;o<br />2a p. s. &ndash;&agrave;&hellip;e 2a p. s. &ndash;&hellip;e 2a p. s. &ndash;&hellip;e<br />3a p. s. &ndash;&agrave;&hellip;a 3a p. s. &ndash;&hellip;e 3a p. s. &ndash;&hellip;e<br />1a p. pl. &ndash;&agrave;mmo 1a p. pl. &ndash;&igrave;mmo 1a p. pl. -&igrave;mmo<br />2a p. pl. &ndash;&agrave;te 2a p. pl. &ndash;&igrave;te 2a p. pl. -&igrave;te<br />3a p. pl. &ndash;&agrave;&hellip;ano 3a p. pl. &ndash;&hellip;eno 3a p. pl. &ndash;&hellip;eno</p>
<p><b><br />Congiuntivo imperfetto </b></p>
<ul>
<li>
Con le prime tre persone del singolare e con la terza persona plurale dell’indicativo presente e del congiuntivo presente la rima di una voce verbale non è sempre identica a quella di altri verbi della prima coniugazione. Essa è per lo più una rima imperfetta o, più propriamente, un’assonanza. Ad esempio, nella prima persona singolare dei verbi cantà  e parlà tra una ‘rima’ e le altre sono identiche solo la vocale tonica e la vocale finale –o: canto e parlo.
</li>
<li>
Nel  presente indicativo dei verbi della seconda coniugazione, eccettuate la prima e la seconda persona plurale, non è possibile indicare le rime possibili: sono molto numerose. Ad esempio, per la prima persona potremmo avere  -àtto da vàttere, éco da vedé, -èngo da tené, -énno da scénnere, -ètto da mèttere….
</li>
</ul>
<p><br />1a p. s. &ndash;&agrave;sse 1a p. s. &ndash;&eacute;sse 1a p. s. &ndash;&eacute;sse 2a p. s. &ndash;&agrave;sse 2a p. s. &ndash;&igrave;sse 2a p. s. &ndash;&igrave;sse<br />3a p. s. &ndash; &agrave;sse 3a p. s. &ndash; &eacute;sse 3a p. s. - &eacute;sse<br />1a p. pl. &ndash;&agrave;ssemo 1a p. pl. &ndash;&eacute;ssemo 1a p. pl. -&eacute;ssemo<br />2a p. pl. &ndash;&agrave;sseve 2a p. pl. &ndash;&igrave;sseve 2a p. pl. -&igrave;sseve<br />3a p. pl. &ndash;&agrave;sseno 3a p. pl. &ndash;&eacute;sseno 3a p. pl. &ndash;&eacute;sseno</p>
<p> <b>Condizionale presente </b></p>
<p>1a p. s. &ndash;&igrave;a 1a p. s. &ndash;&igrave;a 1a p. s. &ndash;&igrave;a<br />2a p. s. &ndash;&igrave;sse 2a p. s. &ndash;&igrave;sse 2a p. s. &ndash;&igrave;sse<br />3a p. s. &ndash; &igrave;a 3a p. s. &ndash; &igrave;a 3a p. s. - &igrave;a<br />1a p. pl. &ndash;&igrave;amo 1a p. pl. &ndash;&igrave;amo 1a p. pl. -&igrave;amo<br />2a p. pl. &ndash;&igrave;sseve 2a p. pl. &ndash;&igrave;sseve 2a p. pl. -&igrave;sseve<br />3a p. pl. &ndash; &igrave;ano 3a p. pl. &ndash; &igrave;ano 3a p. pl. &ndash; &igrave;ano</p>
<p> <b> Imperativo </b></p>
<p>2a p. s. &ndash;&agrave;&hellip;a3 2a p. s. &ndash;&hellip;e 2a p. s. &ndash;&hellip;e<br />1a p. pl. &ndash;&agrave;mmo 1a p. pl. &ndash;&igrave;mmo 1a p. pl. &ndash;&igrave;mmo<br />2a p. pl. &ndash;&agrave;te 2a p. pl. &ndash;&igrave;te 2a p. pl. &ndash;&igrave;te</p>
<p> <b>Participio </b></p>
<p>P. presente -&agrave;nte P. presente -&egrave;nte P. presente -&egrave;nte P. passato -&agrave;to P. passato -&ugrave;to P. passato -&ugrave;to</p>
<p><b> Gerundio </b></p>
<p>Presente -&agrave;nno Presente -&egrave;nno Presente -&egrave;nno</p>
</div>






                            </Col>
                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default InfoRimario;
