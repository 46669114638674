import { Configuration } from '../../constants/Configuration';
//const superagent = require("superagent");
import superagent from 'superagent';


/**
 * 
 * @param Category
 * @returns { Promise<*> }
 */


function list(category, order){
    var url = ""; 
    if(category == "Parole"){
        url = Configuration.ENDPOINT.replace('{id}', 'v8yo5xru6b') + 'parola/listaParole/'+order[0]+'/'+ order[1];
    }else if(category == "Documenti"){
        url = Configuration.ENDPOINT.replace('{id}', '6lj9h6i6ob') + 'documento/listaDocumenti/'+order[0]+'/'+ order[1];
    }else if(category == "Canzoni"){
        url = Configuration.ENDPOINT.replace('{id}', 'v8yo5xru6b') + 'testo/listaAllTesti/canzone/'+order[0]+'/'+ order[1];
    }else if(category == "Proverbi"){
        url = Configuration.ENDPOINT.replace('{id}', 'v8yo5xru6b') + 'testo/listaAllTesti/proverbio/'+order[0]+'/'+ order[1];
    }else{
        url = Configuration.ENDPOINT.replace('{id}', '6lj9h6i6ob') + 'dashboard/getAllTestiByCategoria/'+ category.toLowerCase() +'/'+order[0]+'/'+ order[1];
    }

    return superagent.get(url, {})
    .set('accept', 'json')
    .send();
}

const Category = {
    list
}

export default Category;