import React, { Component } from 'react';
import {Col, Row, Button, Card, Modal} from 'react-bootstrap';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './Word.css';
import WordDetail from '../../services/Home/DettaglioParola';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Loader from '../../components/Loader/LoadingFullScreen';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import MediaQuery from 'react-responsive'


// One item component
// selected prop will be passed
const MenuItem = ({ fonetica, funzione_grammaticale, nota, parola, traduzione, _id, type, load}) => {

    return (
        <div className={"slider_item_word"}>
            {/*<a href={'#/word/' + _id} className="no-decoration">*/}
                <Card className="card_f54w">
                    <div className={`${"card-preview"} ${load ? "show" : ""}`}>
                        <div className="card-title" />
                        <div className="card-t1" />
                        <div className="card-t2" />
                    </div>
                    <Card.Header as="h5">
                        {(type ? (
                            <div className="label-card btn-primary">{type}</div>
                        ): "")}
                    </Card.Header>
                    <Card.Body>
                        <Card.Title><p><span>{parola}</span></p>

                        {
                          (parola.mediaCoda && <div><FontAwesomeIcon icon={faPlayCircle} /> Riproducibile</div>)
                        }

                        </Card.Title>
                        <Card.Text>{fonetica}</Card.Text>
                        <small><i>{funzione_grammaticale}</i></small>
                        <p className="link"><FontAwesomeIcon icon={faChevronRight} /></p>
                    </Card.Body>
                </Card>
            {/*</a>*/}
        </div>
    );
};

// All items component
// Important! add unique key
export const Menu = (list, sel, load) => list.map(el => {
    const { fonetica, funzione_grammaticale, nota, parola, traduzione, _id } = el;

    return (
        <MenuItem
            key={_id}
            fonetica={fonetica}
            funzione_grammaticale={funzione_grammaticale}
            nota={nota}
            parola={parola}
            traduzione={traduzione}
            _id={_id}
            type={false}
            load={load}
        />
    );
});


const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};

function renderTooltip(props) {
  return <Tooltip {...props}>Simple tooltip</Tooltip>;
}

const ArrowLeft = Arrow({ text: <FontAwesomeIcon icon={faChevronLeft} /> , className: 'arrow-prev' });
const ArrowRight = Arrow({ text: <FontAwesomeIcon icon={faChevronRight} />, className: 'arrow-next' });

class Word extends Component{

    constructor(props){
        super(props);

        this.state = {
            trends: null,
            detail: { regoleVirtuals : null},
            play: false,
            idLoad: 0,
            noSong: false,
            playerProgressBar: 0,
            prev: [],
            next: [],
            selected: false,
            load: true,
            loadPage: true,
            showModal: false,
            desc: ''
        }

        this.audio = new Audio("")

    }

    doesFileExist(urlToFile) {
          var xhr = new XMLHttpRequest();
          xhr.open('HEAD', urlToFile);
          xhr.send();

          if (xhr.status == "404") {
              return false;
          } else {
              return true;
          }
      }


    componentWillMount = () =>{
        document.body.className = 'word';
        window.scrollTo(0, 0);

        const { id } = this.props.match.params;

        if(id !== undefined){
            WordDetail.word(id).then(response => {
                if(response.text){
                    response = JSON.parse(response.text);
                    console.log(response);
                    this.setState({
                        detail: response.parola,
                        prev: response.prev,
                        next: response.succ,
                        //loadPage: false
                    });

                    if(response.parola !== undefined){
                        const $me = this;
                        if(this.state.detail.mediaCoda && this.state.detail.mediaCoda.length)
                        {
                          this.audio = new Audio("https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/parole/" + this.state.detail._id + "?random_number=" +new Date().getTime());
                          console.log("a");
                          $me.setState({
                              noSong: true
                          });
                          this.audio.oncanplay = function(){
                              $me.setState({
                                  noSong: true
                              });
                            }


                        }
                        else {
                          console.log("b");
                          $me.setState({
                              noSong: false
                          });
                        }
                    }
                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });
        }

        const self = this;
        setTimeout(function(){
            self.setState({
                load: false
            });
        }, 1000);

        if(this.state.detail == ""){
            setTimeout(function(){
                $('.lp2').fadeOut('fast', function(){
                    $('.lp1').fadeIn();
                });
            }, 500);
        }

    }


    componentWillUnmount = () =>{

        if(this.state.load === false){
            this.setState({
                load: true
            });

        }

        if(this.state.play){
            this.audio.currentTime = 0;
            this.audio.pause();
            this.setState({
                play: false
            });
        }
    }

    componentDidUpdate = () =>{
        //if(this.audio.play == false){
            window.scrollTo(0, 0);
        //}

        const { id } = this.props.match.params;

        if(id !== null){
            if(this.state.idLoad !== id){

                WordDetail.word(id).then(response => {
                    if(response.text){
                        response = JSON.parse(response.text);
                        this.setState({
                            detail: response.parola,
                            prev: response.prev,
                            next: response.succ,
                            loadPage: false
                        });
                        if(response.parola !== undefined){
                            const $me = this;


                            if(this.state.detail.mediaCoda && this.state.detail.mediaCoda.length)
                            {
                              this.audio = new Audio("https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/parole/" + this.state.detail._id + "?random_number=" +new Date().getTime());

                              $me.setState({
                                  noSong: true
                              });
                              this.audio.oncanplay = function(){
                                console.log($me.state.noSong + 'a');
                                  $me.setState({
                                      noSong: true
                                  });
                                }


                            }
                            else {
                              this.audio = '';
                              $me.setState({
                                  noSong: false
                              });
                            }
                        }

                        const self = this;
                        setTimeout(function(){
                            self.setState({
                                load: false
                            });
                        }, 1000);

                        if(!this.state.loadPage){
                            $('.lp1').css('display', 'none');
                            $('.lp2').css('display', 'block');
                            setTimeout(function(){
                                $('.lp2').fadeOut('fast', function(){
                                    $('.lp1').fadeIn();
                                });
                            }, 500);
                        }
                    }else{
                        console.log(response);
                    }
                }).catch(err => {
                    console.log(err);
                });

                if(this.state.noSong){
                    setTimeout(function(){
                        $('.play_casdf').fadeIn('fast');
                    }, 300);
                }

                this.setState({
                    idLoad: id,
                    load: true
                });

                if(this.state.play){
                    this.togglePlay();
                    this.audio.currentTime = 0;
                }
            }
        }
    }

    handleClose = () =>{
        this.setState({
            showModal: false,
        });
    }

    handelOpen = (t) =>{
        this.setState({
            showModal: true,
            desc: t
        });
    }


    togglePlay = () => {
        const me_ = this;
        this.setState({
            play: !this.state.play
        }, () => {
            this.state.play ? this.audio.play() : this.audio.pause();
        });
        console.log(this.state.play)
        var player = this.audio;
        player.addEventListener("timeupdate", function() {
            var currentTime = player.currentTime;
            var duration = player.duration;
            console.log(player.currentTime);
            console.log(player.duration);

            if(currentTime >= duration){
                me_.setState({
                    play: false
                }, () => {
                    me_.state.play ? me_.audio.play() : me_.audio.pause();
                });
            }
        });
    }

    onSelect = (key) => {
        this.setState({ selected: key });
        this.props.history.push('/word/' + key);
    }

    render(){
      const safariRenderHack = { opacity: 1 % 2 ? 1 : 0.99 };


        const { detail, playerProgressBar, noSong, prev, next, selected, load, loadPage } = this.state;
        const prev_menu = Menu(prev, selected, load);
        const next_menu = Menu(next, selected, load);
        console.log(noSong);
        if(noSong){
            setTimeout(function(){
                $('.play_casdf').css('display', '');
            }, 300);
        }

        return (
            <div>
                <Loader />
                <Header />

                <div className="pg-load">
                    {/*
                        Section Description
                    */}
                    <section id="" className="sectionpadd lateral-50-padd">
                        <Row className="lp1" style={{display: 'none'}}>
                            <Col md={12}>
                                <h1>{(detail !== null ? detail.parola : '')} {noSong  ? (<Button variant={this.state.play ? "danger" : "success"} size="sm" onClick={this.togglePlay}  className="play_casdf" style={{zIndex: '99'}}>{this.state.play ? <span><FontAwesomeIcon icon={faPauseCircle} /> Pausa</span> : <span><FontAwesomeIcon icon={faPlayCircle} /> Riproduci</span>}</Button>) : ""}
                                </h1>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <h3 className="subtitle_word">Funzione Grammaticale</h3>
                                    <p className="description">{(detail !== null ? detail.funzione_grammaticale : '')}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <h3 className="subtitle_word">Traduzione</h3>
                                    <p className="description">{(detail !== null ? detail.traduzione : '')}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <h3 className="subtitle_word">Fonetica</h3>
                                    <p className="description">{(detail !== null ? detail.fonetica : '')}</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <h3 className="subtitle_word">Regole</h3>
                                    <p className="description">{
                                    (this.state.detail.regoleVirtuals ? this.state.detail.regoleVirtuals.map((val) => (
                                        <p><strong>{val.titolo}</strong>: {val.descrizione} <Button variant="info" renderAs="button" onClick={() => this.handelOpen(val)}><FontAwesomeIcon icon={faInfo}></FontAwesomeIcon></Button>
                                        </p>
                                    )) : '')
                                  }</p>
                                </div>
                            </Col>

                            <Col md={12} className="text-center bt_ftsd"> <Link to="../Contact"><Button variant="warning" renderAs="button"><span>Segnalaci un errore</span></Button></Link></Col>
                        </Row>

                        <Row className="lp2">
                            <Col md={12}>
                                <div className="display-flex" >
                                    <div className="scheleton-text-h1"></div>
                                    <div className="scheleton-button"></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="scheleton-text-h2" style={{width: "5%"}}></div>
                                    <div className="scheleton-text-p" style={{width: "24%"}}></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="scheleton-text-h2" style={{width: "31%"}}></div>
                                    <div className="scheleton-text-p" style={{width: "16%"}}></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="scheleton-text-h2" style={{width: "20%"}}></div>
                                    <div className="scheleton-text-p" style={{width: "10%"}}></div>
                                </div>
                            </Col>
                            <Col md={12} className="text-center bt_ftsd"></Col>
                        </Row>
                    </section>

                    {/*
                        Section Current Trends
                    */}
                    <section id="currentTrends" className="sectionpadd lateral-50-padd word_sect">
                        <Row>
                            <Col md={12} className="text-center bt_ftsd">
                                <h3 className="title">Potrebbe interessarti</h3>
                            </Col>

                            <Col md={12}>
                                <ScrollMenu
                                    data={prev_menu}
                                    arrowLeft={ArrowLeft}
                                    arrowRight={ArrowRight}
                                    wheel={false}
                                    hideArrows={false}
                                    onSelect={this.onSelect}
                                    selected={selected}
                                    hideSingleArrow={true}
                                    alignCenter={false}
                                    inertiaScrolling={false}
                                />
                            </Col>
                            <Col md={12}>
                                <ScrollMenu
                                    data={next_menu}
                                    arrowLeft={ArrowLeft}
                                    arrowRight={ArrowRight}
                                    wheel={false}
                                    hideArrows={false}
                                    onSelect={this.onSelect}
                                    selected={selected}
                                    hideSingleArrow={true}
                                    alignCenter={false}
                                    inertiaScrolling={false}
                                />
                            </Col>
                        </Row>
                    </section>
                </div>


                {/*
                    Section Footer
                */}

                <Footer />


                <Modal show={this.state.showModal}>
                    <Modal.Header>
                      <Modal.Title>Informazioni aggiuntive</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><Row>
                    <Col md={12}>
                    <Modal.Title>Esempi: </Modal.Title>
                    {this.state.desc.esempio}
                    </Col>
                    <Col md={12}>
                    <Modal.Title>Approfondimenti: </Modal.Title>
                    {this.state.desc.descrizione_esempio}
                    </Col>
                    </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleClose}>
                        Chiudi
                      </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}

export default Word;
