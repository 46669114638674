import icon1 from '../assets/img/icons/music.png';
import icon2 from '../assets/img/icons/poetry.png';
import icon3 from '../assets/img/icons/prose.png';
import icon4 from '../assets/img/icons/research.png';
import icon5 from '../assets/img/icons/words.png';
import icon6 from '../assets/img/icons/rimario.png';

export const Configuration = {
    "SITE_NAME" : "NapulApp",
    "ENDPOINT" : "https://{id}.execute-api.eu-south-1.amazonaws.com/dev/",
    "DOCUMENT" : "https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/documenti/",
    "MEDIA" : "https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/parole/",
    "CATEGORY": [{
                    name: "Parole",
                    icon: icon5
                },
                {
                    name: "Poesie",
                    icon: icon2
                },
                {
                    name: "Canzoni",
                    icon: icon1
                },
                {
                    name: "Proverbi",
                    icon: icon3
                },
                {
                    name: "Documenti",
                    icon: icon4
                },
                {
                    name: "Rimario",
                    icon: icon6
                }]
};
