import React, { Component } from 'react';
import {Container, Col, Row, Button, Form} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './Contact.css';

import axios, { post } from 'axios';
import Loader from '../../components/Loader/LoadingFullScreen';


class Contact extends Component{

    constructor(props){
        super(props);

        this.state = {
            name: '',
            email: '',
            message: '',
            errorEmail: false,
            sendEmail: false,
            response: ''
        }
    }

    componentDidMount = () =>{
        document.body.className = 'contact';
        window.scrollTo(0, 0);
    }

    componentDidUpdate = () =>{
        //window.scrollTo(0, 0);
    }

    handleChange = ($event) =>{
        $event.preventDefault();
        const cnt = $event.target.name;

        if(cnt == "name"){
            this.setState({
                name: $event.target.value
            });
        }else if(cnt == "email"){

            const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            re.test($event.target.value);
            this.setState({
                email: $event.target.value,
                errorEmail: !re.test($event.target.value)
            });
        }else if(cnt == "message"){
            this.setState({
                message: $event.target.value
            });
        }

        if(this.state.name.length >= 1 && this.state.email.length >= 5){
            this.setState({
                sendEmail: true
            });
        }
    }


    handleSendForm = (e) => {
        this.setState({
            sendEmail: false
        });
        const self = this;
        //document.getElementById('sendmess').submit();
        /*console.log(this.state);*/
        this.fileUpload(null).then((response)=>{
            self.setState({
                sendEmail: true,
                response: response.data
            }); console.log(response.data, response.data.includes("OK:"));

            if(response.data.includes("OK:")){
                self.setState({
                    name: '',
                    email: '',
                    message: '',
                });
            }
        }).catch((error) => {
            console.log(error);
        });

        e.preventDefault();
    }

    fileUpload(file){
        const url = 'http://paco.wc.lt/zetaware/napulappSiteEmailForm/form.php';
        //const url = 'http://localhost/test/form.php';

        const formData = new FormData();
        formData.append('name', this.state.name);
        formData.append('email', this.state.email);
        formData.append('message', this.state.message);

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }
        return post(url, formData,config);
    }

    render(){

        return (
            <div>
                <Loader />
                <Header />

                <section className="pg-load no-padding" style={{padding: "170px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-left" md={6} xs={12}>
                                <h3 className="title text-left" style={{marginBottom: "30px"}}>Chi siamo</h3>

                                <p className="p_contact">
                                    <strong>Giovanni Vitale</strong> è prof. emerito di Arte scenica presso i Conservatori Statali di Musica di Foggia, Benevento e Salerno. Ha frequentato la Scuola d’Arte drammatica, presso il Circolo Artistico Politecnico di Napoli negli anni 1967-68 e 1968-69.
                                </p>

                                <p className="p_contact">
                                    La sua professione lo ha condotto ad approfondire, oltre alla recitazione ed altre discipline teoriche, la dizione della lingua italiana e del dialetto napoletano. Tra le competenze artistiche ha esercitato in particolar modo la lettura espressiva e la dizione poetica, sia dell’italiano che del dialetto napoletano. Tali competenze gli consentono di proporre agli utenti di Napulapp la lettura in voce di vari testi, siano essi proverbi, poesie o canzoni.
                                </p>

                                <p className="p_contact">
                                    Ha pubblicato, tra l’altro, <i>Manuale di dizióne per cantanti</i>, Napoli 1987, <i>Dialetto Napoletano, manuale di scrittura e di dizione</i>, Napoli 2009, <i>Affonzo de Liguori, Nòbbele napulitano che pprimma fuie paglietta e ppo’ Prèvete Santo</i>, Napoli 2012.
                                </p>

                                <p className="p_contact">
                                    È laureato in Pedagogia, Psicologia, Teologia. Diplomato in Canto (vecchio ordinamento).
                                </p>

                                <p className="p_contact">
                                    Oltre a curare il continuo aggiornamento e l’implementazione di Napulapp, esercita la libera professione di Psicologo-psicoterapeuta.
                                </p>

                                <p className="p_contact">
                                    Scopri di più su <a href="http://www.giovannivitale.it" target="_blank">www.giovannivitale.it</a>
                                </p>
{/*
                                <div className="info info-horizontal">
                                    <div className="icon icon-primary">
                                        <FontAwesomeIcon className="icons" icon={faMapMarkerAlt} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Puoi cercarci negli uffici</h4>
                                        <p> Via dei criceti, 48
                                            <br /> 7652 Città,
                                            <br /> Italia
                                        </p>
                                    </div>
                                </div>

                                <div className="info info-horizontal">
                                    <div className="icon icon-primary">
                                        <FontAwesomeIcon className="icons" icon={faPhone} />
                                    </div>
                                    <div className="description">
                                        <h4 className="info-title">Puoi contattarci su</h4>
                                        <p> Marco Rossi
                                            <br /> +39 000 000 0000
                                            <br /> Italia
                                        </p>
                                    </div>
</div> */}
                            </Col>
                            <Col md={6} xs={12}>
                                <h3 className="title text-left onlyMoby" style={{marginBottom: "30px"}}>Hai qualche domanda?</h3>

                                <Form>
                                    <Form.Group controlId="exampleForm.ControlInput1">
                                        <Form.Control type="text" className="inputForm name" name="name" onChange={this.handleChange} value={this.state.name} placeholder="Nome completo: Marco Rossi" />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlInput2">
                                        <Form.Control type="email" className={"inputForm email " + (this.state.errorEmail ? "error" : "")} name="email"  value={this.state.email} onChange={this.handleChange} placeholder="La tua Email: name@example.com" />
                                    </Form.Group>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Control as="textarea" className="inputForm message" name="message" onChange={this.handleChange} rows="4"  value={this.state.message} placeholder="Scrivi un messaggio..." />
                                    </Form.Group>

                                    <Button variant="primary" disabled={this.state.sendEmail === false} onClick={this.handleSendForm} className="btn-moby">Invia Messaggio</Button>

                                    <div>
                                        {this.state.response}
                                    </div>

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default Contact;
