import React, { Component } from 'react';
import SearchService from '../../services/Search/Search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faSearch } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button} from 'react-bootstrap'


class Search extends Component{

    constructor(props){
        super(props);

        this.state = {
            searchVal: "",
            trends: null,
            load: false,
            inLoad: false,
            results: []
        };
    }

    handleChange = (e) => {
        this.setState({
            inLoad: true,
            searchVal: e.target.value
        });

        setTimeout(() => {
            this.setState({
                load: (this.state.searchVal.length === 0 ? false : true)
            });
        }, 50);


        if(this.props.style === "Rimario"){

            SearchService.searchRimario(e.target.value, [0,5]).then(response => {
                if(response.text){
                    response = JSON.parse(response.text);
                    this.setState({
                        results: response.parole,
                        inLoad: false
                    });
                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });

        }else{
            SearchService.search(e.target.value, [0,5]).then(response => {
                if(response.text){
                    response = JSON.parse(response.text);
                    this.setState({
                        results: response,
                        inLoad: false
                    });
                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });
        }

        e.preventDefault();
    }

    moreItems = () =>{
        this.props.moreItems(this.state.searchVal);
        this.setState({
            searchVal: ''
        });
    }

/*
    renderList = (results) => {

        if(results !== undefined){

            Object.keys(results).map((key, val) => {
                const obj = Object.values(results)[val];
                if(typeof obj === 'object'){
                    if(obj.length >= 1){
                        console.log("Mostrp", key, obj,obj.length);

                        Object.values(obj).map((okay) => {
                            console.log(okay);
                        });
                        return (
                            <div>
                                <p className="sub_t">Risultati di ricerca per <span className="right">Parola</span></p>
                                <ul className="list">
                                    <li>
                                        <a href="">O' cafè <small><strong>Il caffè</strong> aggettivo sostantivo maschile</small></a>
                                    </li>
                                </ul>
                            </div>
                        );
                    }else{
                        // Empty Object
                    }
                }
            })
        }
    }*/


    render(){

        const { style } = this.props;
        const { results, inLoad, load, searchVal } = this.state;


        return(
            <div className="search_nav">
                <form>
                    <fieldset>
                        {(style === undefined ? (
                            <legend>Cosa vuoi cercare?</legend>
                        ) : "")}

                        <div className="inner-form">
                            <div className="input-field">
                                <button className="btn-search" type="button">
                                    {(inLoad ? (
                                        <span className="btn-loader"><span></span><span></span><span></span><span></span></span>
                                    ) : (
                                        <FontAwesomeIcon icon={faSearch} />
                                    ))}
                                </button>
                                <input id="search" type="text" className={(searchVal.length > 0 && inLoad === false ? "focused" : "")} onChange={this.handleChange} placeholder={this.props.placeholder} autoComplete="off"/>

                                <div className={"loadAutocompleteLists " + (searchVal.length > 0 ? "show" : "") + " " + (load ? 'anim' : '')}>

                                    {
                                        style === "Rimario" ? (
                                            <div>
                                                <p className="sub_t">Risultati di ricerca per: <span className="right">{searchVal}</span></p>
                                                <ul className="list">
                                                    {
                                                        Object.values(results).map((okay, index) => (
                                                            <li key={index}>
                                                                <a href={"#/word/" + okay._id}>{okay.parola} <small><strong>{okay.traduzione}</strong> {okay.funzione_grammaticale}</small></a>
                                                            </li>
                                                        ))
                                                    }

                                                    
                                                </ul>
                                                <center>
                                                    {
                                                        Object.values(results).length >= 5 ?
                                                            <Button onClick={this.moreItems} variant="primary" size="sm" style={{margin: 10}}>Mostra altro </Button>
                                                        : ""
                                                    }
                                                </center>
                                            </div>
                                        ): (
                                            Object.keys(results).map((key, val) => (
                                                (typeof Object.values(results)[val] === 'object' ? (
                                                    (Object.values(results)[val].length >= 1 ? (
                                                        (style !== undefined  ? (
                                                            (style.toLowerCase() === key.toLowerCase() ? (
                                                                <div key={key}>
                                                                    <p className="sub_t">Risultati di ricerca per: <span className="right">{key}</span></p>
                                                                    <ul className="list">
                                                                        {
                                                                            Object.values(Object.values(results)[val]).map((okay, index) => (
                                                                                (okay.categoria === undefined ? (
                                                                                    <li key={index}>
                                                                                        <a href={"#/word/" + okay._id}>{okay.parola} <small><strong>{okay.traduzione}</strong> {okay.funzione_grammaticale}</small></a>
                                                                                    </li>
                                                                                ) : (
                                                                                    <li key={index}>
                                                                                        <a href={"#/text/" + okay._id}>{okay.titolo} </a>
                                                                                    </li>
                                                                                ))
                                                                            ))
                                                                        }
                                                                    </ul>
                                                                    <center>
                                                                        {
                                                                            Object.values(results)[val].length >= 5 ?
                                                                                <Button onClick={this.moreItems} variant="primary" size="sm" style={{margin: 10}}>Mostra altro </Button>
                                                                            : ""
                                                                        }
                                                                    </center>
                                                                </div>
                                                            ) : "")
                                                        ) : (
                                                            <div key={key}>
                                                                <p className="sub_t">Risultati di ricerca per: <span className="right">{key}</span></p>
                                                                <ul className="list">
                                                                    {
                                                                        Object.values(Object.values(results)[val]).map((okay, index) => (
                                                                            (okay.categoria === undefined ? (
                                                                                <li key={index}>
                                                                                    <a href={"#/word/" + okay._id}>{okay.parola} <small><strong>{okay.traduzione}</strong> {okay.funzione_grammaticale}</small></a>
                                                                                </li>
                                                                            ) : (
                                                                                <li key={index}>
                                                                                    <a href={"#/text/" + okay._id}>{okay.titolo} </a>
                                                                                </li>
                                                                            ))
                                                                        ))
                                                                    }
                                                                </ul>
                                                                {
                                                                    Object.values(results)[val].length > 5 ?
                                                                        "s"
                                                                    : ""
                                                                }
                                                            </div>
                                                        ))
                                                    ) : '')
                                                ) : (
                                                   ""
                                                ))
                                            ))
                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        );
    }

}

export default Search;
