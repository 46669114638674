import React, { Component } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCommentAlt, faIdCard, faArchive } from '@fortawesome/free-solid-svg-icons';
import { Configuration } from '../../constants/Configuration';
import logo from '../../assets/img/logo.png';


class NavBar extends Component{
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };

    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    locationPathName = () =>{ 
        return window.location.hash;
    }

    render(){

        return (
            <Navbar bg="white" expand="lg" className="nav-fixed">
                <Navbar.Brand href="#/" title={Configuration.SITE_NAME} style={{padding: 0, marginLeft: 10}}>
                    <img src={logo} alt={Configuration.SITE_NAME} width="90px" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="display-inline-block">
                    <Nav className="nav justify-content-center" variant="pills" activeKey={this.locationPathName()}>
                        <Nav.Item>
                            <Nav.Link href="#/"><FontAwesomeIcon icon={faHome} /> Home</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#/Categorie/Parole" className={(window.location.hash.split('#/')[1].split('/')[0] === "Categorie" ? 'active' : '')}><FontAwesomeIcon icon={faArchive} /> Categorie</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#/Requests"><FontAwesomeIcon icon={faCommentAlt} /> Richieste</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#/Contact" ><FontAwesomeIcon icon={faIdCard} /> Contatti</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }

}


export default NavBar;

