import React, { Component } from 'react';
import {Container, Col, Row, Button} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import './Home.css';
import Footer from '../../components/Footer/Footer.js';
import WordCard from '../../components/Cards/Cards';
import TrendsService from '../../services/Home/Trends';
import SearchComponent from '../../components/Search/Search';
import CategoryList from '../../components/CategoryList/CategoryList.js';
import Loader from '../../components/Loader/LoadingFullScreen';


class Home extends Component{
    constructor(props){
        super(props);

        this.state = {
            trends: null,
        };
    }

    componentDidMount = () =>{
        document.body.className = 'home';
        window.scrollTo(0, 0);

        TrendsService.word([0,8]).then(response => {
            if(response.text){
                response = JSON.parse(response.text);
                this.setState({
                    trends: response.parole
                });
            }else{
                console.log(response);
            }
        }).catch(err => {
            console.log(err);
        });

        this.setState({
            slider: document.querySelector(".slider_body")
        });
    }

    callbackList = ($id) => {
        this.props.history.push('/Categorie/' + $id);
    }

    render(){

        return (
            <div>
                <Loader />
                <Header />

                <section id="header" className="header-home">
                    <div>
                        <SearchComponent />
                    </div>
                </section>

                {/*
                    Section Categories
                */}
                <section id="categories">
                    <h3 className="title">Categorie</h3>

                    <div className="slider-card" >
                        <div className="slider_menu">
                            <div className="slider_body">
                                <CategoryList callback={this.callbackList.bind(this)} alignCenter={true} active={"Parole"} />
                            </div>
                        </div>
                    </div>
                </section>

                {/*
                    Section translate
                */}


                <section id="textTranslate" className="bg-f9fafb sectionpadd">
                    <Container>
                        <Row>
                            <Col className="text-center">

                                <p>SANT'ALFONSO, IL PIU' NAPOLETANO DEI SANTI a cura di Giovanni Vitale <a href="https://www.youtube.com/channel/UChVgR7c7ubMAzNi30LMIg0A" target="_blank" class="button-section btn btn-primary btn-sm" variant="primary" size="sm" style={{marginTop: 0, marginLeft: 25, cursor: 'pointer'}}>Clicca qui</a></p>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <section id="textTranslate" className="bg-f9fafb sectionpadd">
                    <Container>
                        <Row>
                            <Col className="text-center">
                                <h3 className="title" style={{fontSize: '1.6rem'}}>Hai bisogno di tradurre un testo?</h3>
                                <p className="subtitle" style={{marginBottom: 50}}>
                                    Se desideri avere la versione di un testo, dal napoletano all’italiano o viceversa, possiamo darti una mano.
                                </p>
                                <h3 className="title" style={{fontSize: '1.6rem'}}>Vuoi che revisioniamo un tuo scritto in dialetto?</h3>
                                <p className="subtitle" style={{marginBottom: 50}}>
                                    Poiché la scrittura del napoletano non è un impegno facile, potresti avere difficoltà a scrivere qualche tua composizione. Sovente potenziali scrittori o poeti rinunciano ai loro progetti perché non sono sicuri della loro scrittura. Noi possiamo rivedere i tuoi scritti e, così, ti aiutiamo ad esprimere senza titubanze il tuo talento.
                                </p>
                                <h3 className="title" style={{fontSize: '1.6rem'}}>Vuoi conoscere la pronuncia di una parola ?</h3>
                                <p className="subtitle" style={{marginBottom: 50}}>
                                    Spesso sorgono in noi dei dubbi sulla pronuncia di una parola napoletana. Se ti interessa, noi possiamo indicarti quella che riteniamo possa essere una corretta pronuncia.
                                </p>
                                <h3 className="title" style={{fontSize: '1.6rem'}}>Vuoi che leggiamo con espressività un tuo testo in napoletano?</h3>
                                <p className="subtitle" style={{marginBottom: 50}}>
                                    Molti poeti e scrittori non hanno una buona competenza nella lettura espressiva e nella dizione poetica. Una cosa è scrivere una poesia, un’altra è saperla leggere con gusto ed arte. Se vuoi una buona lettura dei tuoi testi, siamo a tua disposizione.
                                </p>
                                <Button href="#/Requests" className="button-section" variant="primary" size="lg">Clicca qui</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/*
                    Section Current Trends
                */}
                <section id="currentTrends" className="sectionpadd lateral-50-padd">
                    <Row>
                        <Col className="text-center">
                            <h3 className="title">Tendenze del momento</h3>
                        </Col>
                    </Row>
                    <Row>
                        {
                            (this.state.trends !== null ? this.state.trends.map((val, index) => (
                                <WordCard key={index} _id={val._id} word={val.parola} text={val.fonetica} text2={val.funzione_grammaticale} category={[""]} link={'#/word/' + val._id} />
                            )) : '')
                        }

                    </Row>
                </section>


                {/*
                    Section Footer
                */}

                <Footer />

            </div>
        );
    }
}

export default Home;
