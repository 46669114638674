import React, { Component } from 'react';
import { Col, Row, Button} from 'react-bootstrap'
import Header from '../../components/Header/Header.js';
import Footer from '../../components/Footer/Footer.js';
import './Testo.css';
import TrendsService from '../../services/Home/Trends';
import WordDetail from '../../services/Home/DettaglioParola';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle, faPauseCircle } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Loader from '../../components/Loader/LoadingFullScreen';

class Testo extends Component{

    constructor(props){
        super(props);

        this.state = {
            trends: null,
            detail: null,
            loadPage: true,
            VContenuto: true,
            VFonetica: false,
            VAnalisiFonetica: false,
            noSong: false,
            playerProgressBar: 0,
            play: false,
        }

        this.audio = new Audio("")
    }

    componentDidMount = () =>{
        document.body.className = 'testo';
        window.scrollTo(0, 0);

        TrendsService.word([0,8]).then(response => {
            if(response.text){
                response = JSON.parse(response.text);
                this.setState({
                    trends: response.parole
                });
            }else{
                console.log(response);
            }
        }).catch(err => {
            console.log(err);
        });

        const { tid } = this.props.match.params;

        if(tid !== undefined){
            WordDetail.testo(tid).then(response => {
                if(response.text){
                    response = JSON.parse(response.text);
                    this.setState({
                        detail: response.testo
                    });
                    const $me = this;
                    if(this.state.detail !== undefined){
                        if(this.state.detail.categoria == 'canzone')
                        {
                          if(this.state.detail.mediaCoda && this.state.detail.mediaCoda.length)
                          {
                            this.audio = new Audio("https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/canzone/" + this.state.detail._id + "?random_number=" +new Date().getTime());

                            $me.setState({
                                noSong: true
                            });
                            this.audio.oncanplay = function(){
                              console.log($me.state.noSong + 'a');
                                $me.setState({
                                    noSong: true
                                });
                              }


                          }
                          else {
                            this.audio = '';
                            $me.setState({
                                noSong: false
                            });
                          }
                        }
                        else if(this.state.detail.categoria == 'poesia')
                        {
                          if(this.state.detail.mediaCoda && this.state.detail.mediaCoda.length)
                          {
                            this.audio = new Audio("https://napulapp-store-s3.s3.eu-south-1.amazonaws.com/poesia/" + this.state.detail._id + "?random_number=" +new Date().getTime());

                            $me.setState({
                                noSong: true
                            });
                            this.audio.oncanplay = function(){
                              console.log($me.state.noSong + 'a');
                                $me.setState({
                                    noSong: true
                                });
                              }


                          }
                          else {
                            this.audio = '';
                            $me.setState({
                                noSong: false
                            });
                          }
                        }


                    }

                }else{
                    console.log(response);
                }
            }).catch(err => {
                console.log(err);
            });
        }

        if(this.state.detail != ""){
            setTimeout(function(){
                $('.lp2').fadeOut('fast', function(){
                    $('.lp1').fadeIn();
                });
            }, 800);
        }
    }

    componentWillUnmount = () =>{
        if(this.state.load === false){
            this.setState({
                load: true
            });

        }

        if(this.state.play){
            this.audio.currentTime = 0;
            this.audio.pause();
            this.setState({
                play: false
            });
        }
    }

    componentDidUpdate = () =>{
        if(this.audio.play == false){
            window.scrollTo(0, 0);
        }

    }
    togglePlay = () => {
        const me_ = this;
        this.setState({
            play: !this.state.play
        }, () => {
            this.state.play ? this.audio.play() : this.audio.pause();
        });

        var player = this.audio;
        player.addEventListener("timeupdate", function() {
            var currentTime = player.currentTime;
            var duration = player.duration;

            me_.setState({
                playerProgressBar: (currentTime +.25)/duration*100
            });

            if(currentTime >= duration){
                me_.setState({
                    play: !me_.state.play
                }, () => {
                    me_.state.play ? me_.audio.play() : me_.audio.pause();
                });
            }
        });
    }

    handleOnlyText = () => {
        const self = this;
        self.setState({
            VContenuto: 'loading',
        });
        setTimeout(function(){
            self.setState({
                VContenuto: true,
                VAnalisiFonetica: false,
                VFonetica: false
            });
        }, 400);
    }

    handlePphoneticTranscription = () => {
        const self = this;
        self.setState({
            VFonetica: 'loading',
        });
        setTimeout(function(){
            self.setState({
                VContenuto: false,
                VAnalisiFonetica: false,
                VFonetica: true
            });
        }, 400);
    }

    handleTextTranscriptionPhonetic = () => {
        const self = this;
        self.setState({
            VAnalisiFonetica: 'loading',
        });
        setTimeout(function(){
            self.setState({
                VContenuto: false,
                VAnalisiFonetica: true,
                VFonetica: false
            });
        }, 400);
    }

    render(){

        const { detail, VContenuto, VAnalisiFonetica, VFonetica, playerProgressBar, noSong } = this.state;
        if(noSong){
            setTimeout(function(){
                $('.play_casdf').css('display', '');
            }, 300);
        }

        return (
            <div>
                <Loader />
                <Header />

                <div className="pg-load">
                    {/*
                        Section Description
                    */}
                    <section id="" className="sectionpadd lateral-50-padd">
                        <Row className="lp1" style={{display: 'none'}}>
                            <Col md={12}>
                                <h1 className="title_word">{(detail !== null ? detail.titolo : '')} {noSong  ? (<Button variant={this.state.play ? "danger" : "success"} size="lg" onClick={this.togglePlay} className="play_casdf" style={{display: 'none'}}>{this.state.play ? <span><FontAwesomeIcon icon={faPauseCircle} /> Pausa</span> : <span><FontAwesomeIcon icon={faPlayCircle} /> Riproduci</span>}<div className={this.state.play ? "progressbar" : "progressbar pausa"}><div className="in_progress" style={{width: playerProgressBar + '%'}} /></div></Button>) : ""}</h1>
                            </Col>
                            <div style={{minHeight: 250}}>
                                {
                                    (VContenuto === true ? (
                                        <Col md={12} >
                                            <h3 className="subtitle_word">{(detail !== null ? (detail.contenuto ? "Testo" : "") : "")}</h3>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: detail !== null ? detail.contenuto.split(/\n/g).join('<br />')  : '' }}></p>

                                            <small><i>{(detail !== null ? (detail.autore ? detail.autore + ", "+detail.anno : "") : "")}</i></small>
                                        </Col>
                                    ) : "")
                                }

                                {
                                    (VFonetica === true ? (
                                        <Col md={12} >
                                            <h3 className="subtitle_word">{(detail !== null ? (detail.contenuto_fonetica ? "Testo e trascrizione fonetica" : "Testo") : "")}</h3>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: detail !== null ? detail.contenuto_fonetica.split(/\n/g).join('<br />')  : '' }}></p>

                                            <small><i>{(detail !== null ? (detail.autore ? detail.autore + ", "+detail.anno : "") : "")}</i></small>
                                        </Col>
                                    ): "")
                                }

                                {
                                    (VAnalisiFonetica === true ? (
                                        <Col md={12}>
                                            <h3 className="subtitle_word">Testo, trascrizione fonetica e analisi</h3>
                                            <p className="description" dangerouslySetInnerHTML={{ __html: detail !== null ? detail.contenuto_fonetica_analisi.split(/\n/g).join('<br />')  : '' }}></p>

                                            <small><i>{(detail !== null ? (detail.autore ? detail.autore + ", "+detail.anno : "") : "")}</i></small>
                                        </Col>
                                    ) : "")
                                }

                            </div>
                            <Col md={12} className="text-center bt_ftsd">
                                <div>
                                    {(detail !== null ? (detail.contenuto ? (<Button variant="primary" disabled={VContenuto === 'loading' || VContenuto && !VAnalisiFonetica} onClick={this.handleOnlyText} size="lg" className="play_casdf2">{VContenuto === "loading" ? (<span className="btn-loader"><span></span><span></span><span></span><span></span></span>) : "Solo Testo"}</Button>) : "") : "")}
                                    {(detail !== null ? (detail.contenuto_fonetica ? (<Button variant="primary" disabled={VFonetica === 'loading' || VFonetica === true} onClick={this.handlePphoneticTranscription} size="lg" className="play_casdf2">{VFonetica === "loading" ? (<span className="btn-loader"><span></span><span></span><span></span><span></span></span>) : "Testo e trascrizione fonetica"}</Button>) : "") : "")}
                                    {(detail !== null ? (detail.contenuto_fonetica_analisi ? (<Button variant="primary" disabled={VAnalisiFonetica === 'loading' || VAnalisiFonetica && VContenuto} onClick={this.handleTextTranscriptionPhonetic} size="lg" className="play_casdf2">{VAnalisiFonetica === "loading" ? (<span className="btn-loader"><span></span><span></span><span></span><span></span></span>) : "Testo, trascrizione fonetica ed analisi fonologica"}</Button>) : "") : "")}
                                    <Link to="../Contact"><Button variant="warning" className="play_casdf2" disabled={VAnalisiFonetica === 'loading' || VAnalisiFonetica && VContenuto}><span>Segnalaci un errore</span></Button></Link>
                                </div>
                            </Col>

                        </Row>
                        <Row className="lp2">
                            <Col md={12}>
                                <div className="display-flex" >
                                    <div className="scheleton-text-h1"></div>
                                    <div className="scheleton-button"></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="scheleton-text-h2" style={{width: "5%"}}></div>
                                    <div className="scheleton-text-p" style={{width: "24%"}}></div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <div className="scheleton-text-h2" style={{width: "31%"}}></div>
                                    <div className="scheleton-text-p" style={{width: "16%"}}></div>
                                </div>
                            </Col>
                            <Col md={12} className="text-center bt_ftsd"> </Col>
                        </Row>
                    </section>
                </div>

                {/*
                    Section Current Trends

                <section id="currentTrends" className="sectionpadd lateral-50-padd word_sect">
                    <Row>
                        <Col md={12} className="text-center bt_ftsd">
                            <h3 className="title">Potrebbe interessarti</h3>
                        </Col>
                        {
                            (this.state.trends ? this.state.trends.map((val, index) => (
                                <WordCard key={index} word={val.parola} text={val.fonetica} text2={val.funzione_grammaticale}  link={'#/word/' + val._id} />
                            )) : '')
                            }
                    </Row>
                </section> */}

                {/*
                    Section Footer
                */}

                <Footer />
            </div>
        );
    }

}

export default Testo;
