import { Configuration } from '../../constants/Configuration';
//const superagent = require("superagent");
import superagent from 'superagent';


/**
 * 
 * @param word
 * @returns { Promise<*> }
 */


function word(order){
    return superagent.get(Configuration.ENDPOINT.replace('{id}', '6lj9h6i6ob') + '/dashboard/tendenze/'+order[0]+'/'+ order[1], {})
    .set('accept', 'json')
    .send();
}

const TrendsService = {
    word
}

export default TrendsService;