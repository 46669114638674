import React, { Component } from 'react';
import './Footer.css';
import {Col, Row, Container, Button} from 'react-bootstrap'
import zumbatLogo from '../../assets/img/zumbat-logo.png';
import logo from '../../assets/img/logoWhite.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import Cookies from 'universal-cookie';

class Footer extends Component{
    constructor(props) {
        super(props);

        this.state = {
            popupCookie: true
        };

    }


    componentDidMount = () => {
        const cookies = new Cookies();
        if(!cookies.get('cookiesOK')){
            this.setState({
                popupCookie: true
            });
        }else{
            this.setState({
                popupCookie: false
            });
        }
    }

    acceptCookie = () =>{
        this.setState({
            popupCookie: false
        });
        const cookies = new Cookies();
        cookies.set('cookiesOK', new Date().getTime(), { path: '/' });
    }

    render(){
        return (
            <div>
                {/*
                    Section Social
                */}
                <section id="Social" className="bg-f9fafb no-padding" style={{padding: "40px 0"}}>
                    <Container>
                        <Row>
                            <Col className="text-center">
                                <a href="" className="socialIcon fb"><FontAwesomeIcon icon={faFacebookF} /></a>
                                <a href="" className="socialIcon ins"><FontAwesomeIcon icon={faInstagram} /></a>
                                <a href="" className="socialIcon tw"><FontAwesomeIcon icon={faTwitter} /></a>
                            </Col>
                        </Row>
                    </Container>
                </section>
            <footer className="footer-page">
                <Row style={{padding: "50px 0"}}>
                    <Col md={3} style={{textAlign: 'center'}} className="align-center logo-mob"><img src={logo} alt="NapulApp logo" width="130px" style={{margin: '0 50px'}}/></Col>
                    <Col md={3} className="align-center logo-mob"><a href="http://zumbat.it/"><img src={zumbatLogo} alt="zumbat logo" width="225px" /></a></Col>
                    <Col md={2} className="align-left logo-mob">
                        <h6>Mappa</h6>
                        <ul>
                            <li><a href="#/Home">Home</a></li>
                            <li><a href="#/Categorie/Parole">Categorie</a></li>
                            <li><a href="#/Requests">Richieste</a></li>
                            <li><a href="#/Contact">Contatti</a></li>
                        </ul>
                    </Col>
                    <Col md={4} className="align-left logo-mob">
                        <h6>Contatti</h6>
                        <ul>
                            <li><a href="mailto: info@giovannivitale.it">info@giovannivitale.it</a></li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="text-right footer-copyright">
                        <Container>
                            &copy; 2019 Napulapp | <a href="http://zumbat.it">Zumbat</a> <a href="#">Privacy policy</a> <a href="#">Cookie policy</a>
                        </Container>
                    </Col>
                </Row>
            </footer>
            {(this.state.popupCookie ? (
                <div className="popup_cookie">
                    <div className="popup-container">
                        <div className="align-center">
                            <strong>Usiamo i cookie per migliorare la tua esperienza sul nostro sito.</strong>
                            Continuando a navigare sul nostro sito accetterai l'utilizzo da parte nostra dei&nbsp;<a href="cookie_zumbat.pdf" target="_blank">cookie</a>.
                            <a onClick={this.acceptCookie} className="btn btn-success" style={{marginLeft: 15}}>Accetta</a>
                        </div>
                    </div>
                </div>
            ) : "")}
        </div>
        );
    }

}

export default Footer;
