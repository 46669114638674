import React, { Component } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import './SliderCat.css';
import { Configuration } from '../../constants/Configuration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';


// One item component
// selected prop will be passed
const MenuItem = ({ name, icon, selected,subname }) => {
    return (
        <div className={"slider_item " + (selected ? 'active': '')}>
            <div className="box_slider">
                <img src={icon} className="icon_slider" alt={name} />
                <h3>{subname}</h3>
                <div className="full-card"></div>
            </div>
        </div>
    );
};

// All items component
// Important! add unique key
export const Menu = (list) => list.map(el => {
    const { icon,name } = el;
    var subname;
    if (name == 'Documenti')
     subname = 'Documenti e News';
    else
      subname = name;

    return (
        <MenuItem
            name={name}
            key={name}
            icon={icon}
            subname={subname}
        />
    );
});


const Arrow = ({ text, className }) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({ text: <FontAwesomeIcon icon={faChevronLeft} /> , className: 'arrow-prev' });
const ArrowRight = Arrow({ text: <FontAwesomeIcon icon={faChevronRight} />, className: 'arrow-next' });

class CategoryList extends Component {

    constructor(props){
        super(props);

        this.state = {
            selected: this.props.active
        };
    }

    onSelect = (key) => {
        this.setState({ selected: key });

        if(this.props.callback){
            this.props.callback(key);
        }
    }


    componentDidUpdate = () =>{
        if(this.state.selected !== this.props.active){
            this.setState({
                selected: this.props.active
            });
        }
    }


    render() {
        const { selected } = this.state;
        // Create menu from items
        const menu = Menu(Configuration.CATEGORY, selected);

        return (
            <ScrollMenu
                data={menu}
                arrowLeft={ArrowLeft}
                arrowRight={ArrowRight}
                selected={selected}
                onSelect={this.onSelect}
                wheel={false}
                hideArrows={true}
                hideSingleArrow={true}
                inertiaScrolling={true}
                scrollToSelected={true}
                alignCenter={(this.props.alignCenter ? true : false)}
            />
        );
    }
}



export default CategoryList;
