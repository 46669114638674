import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar.js';


class Header extends Component{

    render(){
        return (
            <header>
                <NavBar  />
            </header>
        );
    }

}

export default Header;

