import { Configuration } from '../../constants/Configuration';
//const superagent = require("superagent");
import superagent from 'superagent';


/**
 * 
 * @param word
 * @returns { Promise<*> }
 */


function word(id){
    return superagent.get(Configuration.ENDPOINT.replace('{id}', 'v8yo5xru6b') + 'parola/getParolaById/' + id, {})
    .set('accept', 'json')
    .send();
}

function testo(id){
    return superagent.get(Configuration.ENDPOINT.replace('{id}', 'v8yo5xru6b') + 'testo/getTesto/' + id, {})
    .set('accept', 'json')
    .send();
}

const WordDetail = {
    word, testo
}

export default WordDetail;